var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"fe7838ecd02da179d7fdc71d76f7d77fcb50d565"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.STAGE,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.5,
  sampleRate: 0.2,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: [
    "fb_xd_fragment",
    "Unexpected EOF",
    "no_get_fingerprint_data",
    "invalid property id",
    "Cannot read properties of null",
    "Fetch is aborted",
    "Failed to fetch",
    "Unexpected end of script",
    "Cannot read property 'apply' of undefined",
    `/Loading chunk [d]+ failed./`,
    "Illegal invocation",
    "getParameter is not a function",
    "Load failed",
    "ResizeObserver loop limit exceeded",
    "Invalid LngLat object: (NaN, NaN)",
    "undefined is not an object (evaluating 't[12]')",
    "ResizeObserver loop limit exceeded",
    "Non-Error promise rejection captured",
    "Cannot read properties of undefined (reading '_avast_submit')",
    "Cannot read property '_avast_submit' of undefined ",
    "Failed to initialize WebGL.",
    "null is not an object (evaluating 'o[0]')",
    "getPercent is not defined",
    "ResizeObserver loop completed with undelivered notifications.",
    "undefined is not an object (evaluating 'this.concat.apply')",
    "Unexpected token '...'. Expected a property name.",
    "Internal error",
  ],
  denyUrls: [
    /tctm\.co\/f\.js/i,
    /polyfill\.io/i,
    /thehotelsnetwork\.com/i,
    /www\.thehotelsnetwork\.com/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
  beforeSend(event) {
    // filter out UnhandledRejection errors that have no information
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (
      event !== undefined &&
      event?.exception !== undefined &&
      event?.exception?.values !== undefined &&
      event?.exception?.values?.length == 1
    ) {
      var e = event?.exception?.values?.[0];
      if (
        (e?.type === "UnhandledRejection" &&
          e?.value === "Non-Error promise rejection captured with value: ") ||
        chunkFailedMessage?.test(e?.value) ||
        e?.value?.indexOf("getImageData") > -1
      ) {
        return null;
      }
    }
    return event;
  },
});
